import { narr_comm_auth_order } from '@utils/sortBooks';

import { NarratorCommentary } from 'shared/interfaces/hadith';

const commenters_dod = {
    'ابن حبان': 354,
    'ابن حجر': 852,
    'يحيى بن معين': 233,
    'أبو حاتم الرازي': 277,
    النسائي: 303,
    'أحمد بن حنبل': 241,
    المزي: 742,
    'أبو زرعة الرازي': 264,
    العجلي: 261,
    'أبو داود السجستاني': 275,
    'محمد بن سعد': 230,
    'ابن عدي': 365,
    البخاري: 256,
    الدارقطني: 385,
    'علي ابن المديني': 234,
    'يحيى القطان': 198,
    'عبد الرحمن بن مهدي': 198,
    الفسوي: 277,
    'شعبة بن الحجاج': 160,
    الساجي: 261,
    العقيلي: 322,
    'الخطيب البغدادي': 463,
    'يعقوب بن شيبة': 262,
    الحاكم: 405,
    'سفيان بن عيينة': 198,
    'ابن خراش': 283,
    الجوزجاني: 259,
    'أبو أحمد الحاكم': 378,
    الأزدي: 230,
    الترمذي: 279,
    'مسلمة بن القاسم': 353,
    'ابن أبي حاتم الرازي': 327,
    'صالح جزرة': 293,
    دحيم: 245,
    'ابن عبد البر': 463,
    'سفيان الثوري': 161,
    'ابن يونس المصري': 347,
    'محمد بن عبد الله بن نمير': 234,
    البزار: 292,
    الفلاس: 249,
    'عبد الله بن المبارك': 181,
    الذهبى: 748,
    'ابن القطان الفاسي': 628,
    'مالك بن أنس': 179,
    'وكيع بن الجراح': 197,
    'محمد بن عبد الله بن عمار الغامدي': 242,
    'ابن قانع': 351,
    'ابن خزيمة': 311,
    مسلم: 261,
    'أبو نعيم الأصبهاني': 430,
    'ابن شاهين': 585,
    الواقدي: 207,
    'أحمد بن صالح المصري': 248,
    الخليلي: 446,
    'عبد الأعلى بن مسهر الغساني': 218,
    'الفضل بن دكين': 219,
    'محمد بن يحيى الذهلي': 258,
    'أبو زرعة الدمشقي': 281,
    'أبو القاسم البغوي': 317,
    الشافعي: 204,
    'محمد بن إسحاق ابن منده': 395,
    'ابن حزم': 456,
    'محمد بن عبد الله مطين الحضرمي': 300,
    'إبراهيم الحربي': 285,
    'أيوب السختياني': 131,
    'يزيد بن هارون': 206,
    'ابن البرقي': 244,
    'عثمان ابن أبي شيبة': 239,
    'أبو عمرو الأوزاعي': 157,
    'علي بن الحسين بن الجنيد النخعي': 291,
    'إسحاق ابن راهويه': 238,
    الشعبي: 100,
    'أبو الوليد الطيالسي': 227,
    الطبراني: 260,
    'أبو داود الطيالسي': 204,
    الزهري: 124,
};

const commenters_sorted_by_dod = Object.entries(commenters_dod)
    .sort((a, b) => {
        return a[1] - b[1];
    })
    .map(([key, value]) => key);

/**
 * Sorts an array of objects based on the death date (dod) of the commenters.
 * If the commenter's death date is not found, it is placed at the end of the list.
 *
 * @param array - Array of objects to be sorted. Each object should have a 'doc_count' and 'key' property.
 * @returns Sorted array of objects.
 */
export const sortNarratorsCommentaryByDod = (
    array: { doc_count: number; key: string }[],
): { doc_count: number; key: string }[] => {
    return array.sort((a, b) => {
        const indexA = commenters_sorted_by_dod.indexOf(a.key);
        const indexB = commenters_sorted_by_dod.indexOf(b.key);

        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
    });
};

/**
 * Sorts an array of NarratorCommentary objects based on the death date (dod) of the commenters.
 * If the death dates are the same, it further sorts by book name, volume, and page number.
 *
 * @param array - Array of NarratorCommentary objects to be sorted.
 * @returns Sorted array of NarratorCommentary objects.
 */
export const sortByCommentersDod = (
    array: NarratorCommentary[],
): NarratorCommentary[] => {
    return array.sort((a, b) => {
        // Get the death date of commenter 'a' and 'b' from the commenters_dod object, default to 1444 if not found
        const dodA =
            commenters_dod[a.commenter as keyof typeof commenters_dod] ?? 1444;
        const dodB =
            commenters_dod[b.commenter as keyof typeof commenters_dod] ?? 1444;

        // Compare death dates
        if (dodA !== dodB) return dodA < dodB ? -1 : 1;

        // If death dates are the same, compare book names
        if (a.book !== b.book) return a.book < b.book ? -1 : 1;

        // If book names are the same, compare volumes (if both are present)
        if (a.volume && b.volume) return a.volume < b.volume ? -1 : 1;

        // If volumes are the same, compare pages (if both are present)
        if (a.page && b.page) return a.page < b.page ? -1 : 1;

        // If all criteria are the same, return 0 (considered equal)
        return 0;
    });
};

export const hadithCommentaryBooksOrder = {
    1: 'سنن أبي داود',
    2: 'علل الترمذي الكبير',
    3: 'جامع الترمذي',
    4: 'سنن النسائى الصغرى',
    5: 'صحيح ابن خزيمة',
    6: 'العلل لابن أبي حاتم',
    7: 'الإلزامات والتتبع للدارقطني',
    8: 'علل الدارقطني',
};

export function sortHadithCommentary(
    data: { doc_count: number; key: string }[],
) {
    return data.sort((a, b) => {
        const orderA = Object.values(hadithCommentaryBooksOrder).indexOf(a.key);
        const orderB = Object.values(hadithCommentaryBooksOrder).indexOf(b.key);
        return orderA - orderB;
    });
}

export const authorsHadithCommentaryOrder = {
    1: 'أبو داود السجستاني',
    2: 'محمد بن عيسى الترمذي',
    3: 'النسائي',
    4: 'ابن خزيمة',
    5: 'عبد الرحمن بن أبي حاتم',
    6: 'أبو الحسن الدارقطني',
};

export function sortHadithCommentaryAuthors(
    data: { doc_count: number; key: string }[],
) {
    return data.sort((a, b) => {
        const orderA = Object.values(authorsHadithCommentaryOrder).indexOf(
            a.key,
        );
        const orderB = Object.values(authorsHadithCommentaryOrder).indexOf(
            b.key,
        );
        return orderA - orderB;
    });
}

export function sortNarratorsCommentaryAuthors(
    data: { doc_count: number; key: string }[],
) {
    return data.sort((a, b) => {
        const orderA =
            narr_comm_auth_order[a.key as keyof typeof narr_comm_auth_order];
        const orderB =
            narr_comm_auth_order[b.key as keyof typeof narr_comm_auth_order];
        return orderA - orderB;
    });
}
