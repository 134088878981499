import useTranslation from '@hooks/useTranslation';
import { Box, Pagination, Typography } from '@mui/material';
import { numberWithCommas } from '@utils/numbersDisplay';

/**
 *
 * 'pages' prop is actually the number of hits / Hadiths / results per page
 * so the naming chosen by reactivesearch is inappropriate
 * e.g., if we had a total of 30,000 unfiltered hadiths, for pages = 5, we would have totalPages = 6000
 */

export interface ResultPaginationProps {
    pages: number;
    totalPages: number;
    currentPage: number;
    setPage: (x: number) => void;
    totalResults: number;
}

const ResultPagination = ({
    pages,
    totalPages,
    currentPage,
    setPage,
    totalResults,
}: ResultPaginationProps) => {
    const page = currentPage + 1 || 0;

    const { t } = useTranslation('library');

    const firstHitOfPage = pages * currentPage + 1;
    const lastHitOfPage = totalResults
        ? Math.min(pages * currentPage + pages, totalResults)
        : pages * currentPage + pages;

    return (
        <Box
            display="flex"
            justifyContent={totalPages > 0 ? 'space-between' : 'left'}
            mt={2}
            mr={2}
        >
            {totalPages > 0 && (
                <Box display="flex">
                    <Typography variant="body1" color="primary">
                        {t('results')}:&nbsp;
                        {numberWithCommas(firstHitOfPage)}
                        &nbsp;{'-'}&nbsp;
                        {numberWithCommas(lastHitOfPage)}&nbsp;
                        {t('of')}&nbsp;{numberWithCommas(totalResults)}
                    </Typography>
                </Box>
            )}
            <Pagination
                dir="rtl"
                count={totalPages < 1000 ? totalPages : 1000}
                page={page}
                onChange={(e, value) => {
                    setPage(value - 1);
                }}
                variant="outlined"
                color="secondary"
                shape="rounded"
                siblingCount={2}
                showFirstButton
                boundaryCount={0}
            />
        </Box>
    );
};

export default ResultPagination;
