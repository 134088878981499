import Image from 'next/image';

import { Box, Typography } from '@mui/material';

import { CustomLinkObject, CustomNodeObject } from './GraphInterfaces';
import { pastelRed } from './Utils';

export interface LegendItem {
    icon?: string;
    description: string;
    color: string;
}

const Legend = ({ legendItems }: { legendItems: LegendItem[] }) => {
    return (
        <Box display="flex" justifyContent="space-between">
            {legendItems.map((item, index) => (
                <Box
                    key={item.icon}
                    display={'flex'}
                    alignItems={'center'}
                    pr={2}
                    pl={index === 0 ? '10px' : undefined}
                >
                    {item.icon ? (
                        <Image
                            src={item.icon}
                            alt={item.description}
                            width={20}
                            height={20}
                        />
                    ) : (
                        <Box
                            sx={{
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                backgroundColor: item.color,
                            }}
                        ></Box>
                    )}
                    <Typography paddingLeft={'10px'} sx={{ color: item.color }}>
                        {item.description}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

type CustomGraphinInput = {
    edges: CustomLinkObject[];
    nodes: CustomNodeObject[];
};

export const makeLegend = (data: CustomGraphinInput): LegendItem[] => {
    let hasCompanion = false;
    let hasAuthor = false;
    let hasUnknown = false;
    let hasNarrator = false;
    data.nodes.forEach((node) => {
        if (node.is_companion) {
            hasCompanion = true;
        } else if (node.node_type === 'Author') {
            hasAuthor = true;
        } else if (node.id === '1131') {
            hasUnknown = true;
        } else if (node.id !== 'p-1') {
            hasNarrator = true;
        }
    });

    const legendItems: LegendItem[] = [];

    if (hasAuthor) {
        legendItems.push({
            icon: '/images/open-book.png',
            description: 'كتاب',
            color: '#000',
        });
    }
    if (hasCompanion) {
        legendItems.push({
            description: 'صحابي',
            color: '#C7956D',
        });
    }
    if (hasNarrator) {
        legendItems.push({
            description: 'راوي',
            color: '#000',
        });
    }

    if (hasUnknown) {
        legendItems.push({
            icon: '/images/filled-unknown-user.png',
            description: 'راوي مجهول',
            color: '#808080',
        });
    }

    legendItems.push({
        icon: '/images/red-up-arrow.png',
        description: 'الحديث المختار',
        color: pastelRed,
    });

    return legendItems;
};

export default Legend;
