import { type ReactNode, useEffect, useState } from 'react';

import { FullScreenHandle } from 'react-full-screen';

import ResultStats from '@components/results/elements/ResultStats';
import MySnackbar, {
    type MySnackbarProps,
} from '@components/snackbar/Snackbar';

import { ReactiveList } from '@appbaseio/reactivesearch';
import { useCommonContext } from '@hooks/commonContext';
import useTranslation from '@hooks/useTranslation';
import LinkIcon from '@mui/icons-material/Link';
import ReportGmailerrorred from '@mui/icons-material/ReportGmailerrorred';
import {
    Alert,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { GridFilterListIcon } from '@mui/x-data-grid';

import ResultPagination from '../ResultPagination';
import { ResultPaginationProps } from '../ResultPagination/ResultPagination';
import theme, { colors } from 'theme';
import { z } from 'zod';

const Data = z.object({
    resultStats: z.object({
        numberOfResults: z.number(),
    }),
    data: z.any().array(),
});

interface IResultItemListProps {
    /**
     * @description ReactiveList component Id for ReactiveSearch
     */
    componentId: string;

    /**
     * @description Reactive base data field for the ReactiveList
     */
    dataField: string;

    /**
     * @description ReactiveList react prop
     */
    dependencies: object;

    /**
     * @description Fields to retrieve from elastic index
     */
    includeFields: string[];

    /**
     * @description resultItems to get result items
     */
    resultItems: (data: any) => any;

    /**
     * @description defaultQuery to get initial results
     */
    defaultQuery?: (...args: any[]) => any;

    /**
     * @description Number of items to show in the list
     * @default 10
     */
    size?: number;

    /**
     * @description Whether to add url params based on filter selection
     * @default true
     */
    URLParams?: boolean;

    /**
     * @description ReactiveList style prop.
     * @default {color: '#778899',marginBottom: 20}
     */
    style?: object;

    /**
     * @description Whether to show result stats
     * @default true
     */
    showResultStats?: boolean;

    /**
     * @description resultStatsMessage
     * default library
     */
    resultStatsMessage?: ReactNode;

    /**
     * @description Whether to use pagination
     * @default true
     */
    showPagination?: boolean;

    /**
     * @description Display a filter icon at the top left of the list
     * @default false
     */
    hideFilter?: boolean;

    /**
     * @description Display a filter icon at the top left of the list
     * @default false
     */
    hideReport?: boolean;

    /**
     * @description function to be called when filter icon is clicked
     */
    onFilterClick?: () => void;

    /**
     * @description Can be used to render JSX elements at the top first level of the list
     */
    headerFirstLevel?: ReactNode;

    /**
     * @description Can be used to render JSX elements at the top second level of the list
     */
    headerSecondLevel?: ReactNode;

    /**
     * @description Component to be rendered when no results are found
     */
    notFoundComponent?: ReactNode;

    /**
     * @description String or JSX Markup to show the user while the data is loading.
     */
    loader?: ReactNode;

    /**
     * @description whether to stream new result updates in the UI. Streaming implies new hits are appended to existing hits.
     * Probably should not used be used in conjunction with showPagination = true. Probably.
     */
    stream?: boolean;

    /**
     * @description id of the container you wish to apply infinite loading on. Note: The container should be scrollable.
     */
    scrollTarget?: string;

    /**
     * @description Can be used to render custom pagination
     */
    onData?: (...args: any[]) => void;

    fullScreenHandle?: FullScreenHandle;
    highlightLegend?: ReactNode;
    hasSelectedFilters?: boolean;
    noResultsMessage?: string;
}

const ResultItemList = ({
    componentId,
    dataField,
    dependencies,
    resultItems,
    includeFields,
    defaultQuery = () => {
        return {
            track_total_hits: true,
            query: {
                match_all: {},
            },
        };
    },
    size = 5,
    URLParams = true,
    style = {
        color: '#778899',
        marginTop: 10,
    },
    showResultStats = true,
    resultStatsMessage,
    hasSelectedFilters,
    showPagination = true,
    hideFilter = false,
    hideReport = false,
    onFilterClick,
    headerFirstLevel,
    headerSecondLevel,
    notFoundComponent,
    highlightLegend,
    loader,
    stream,
    scrollTarget,
    onData,
    fullScreenHandle,
    noResultsMessage,
}: IResultItemListProps) => {
    const { t } = useTranslation('library');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [totalResults, setTotalResults] = useState(0);

    const initialRenderPagination = (props: ResultPaginationProps) => (
        <ResultPagination {...props} />
    );
    const [renderPagination, setRenderPagination] = useState(
        () => initialRenderPagination,
    );
    const { setErrorData } = useCommonContext();
    const [showSnackbar, setShowSnackbar] = useState<MySnackbarProps>({
        message: '',
        show: false,
    });
    const copyURLToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            if (setShowSnackbar)
                setShowSnackbar({
                    message: t('url_copied'),
                    show: true,
                });
        } catch (err) {}
    };

    useEffect(() => {
        const renderWithTotalResults = (props: ResultPaginationProps) => (
            <ResultPagination
                {...props}
                totalResults={totalResults}
                pages={size}
            />
        );
        setRenderPagination(() => renderWithTotalResults);
    }, [totalResults, size]);
    return (
        <>
            <ReactiveList
                componentId={componentId}
                dataField={dataField}
                includeFields={includeFields}
                render={(items) => {
                    if (items.error) {
                        return (
                            <Alert
                                severity="error"
                                sx={{
                                    textAlign: 'center',
                                    width: 'fit-content',
                                }}
                            >
                                {t('fetch_error')}
                            </Alert>
                        );
                    } else {
                        return resultItems(items);
                    }
                }}
                react={dependencies}
                defaultQuery={defaultQuery}
                URLParams={URLParams}
                size={size}
                style={style}
                // @ts-expect-error The stream property exists according to the documentation, but missing in the type declarations
                stream={stream}
                loader={loader}
                showLoader={true}
                pagination={showPagination}
                scrollTarget={scrollTarget}
                scrollOnChange={false}
                onData={(data) => {
                    const parsedData = Data.parse(data);
                    const { resultStats, data: items } = parsedData;
                    setTotalResults(resultStats.numberOfResults);
                    if (onData) onData(data);
                }}
                onError={(e) => {
                    console.error('result item list error', e);
                    setShowSnackbar({
                        show: true,
                        message: t('fetch_error'),
                        severity: 'error',
                    });
                }}
                infiniteScroll={!showPagination}
                renderNoResults={() => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            {headerFirstLevel}
                            {headerSecondLevel}
                            <Box display="flex" flexDirection="row">
                                <Typography
                                    variant="body1"
                                    color="primary"
                                    mr={2}
                                >
                                    {noResultsMessage ?? t('no_results_found')}
                                </Typography>
                                {notFoundComponent && (
                                    <Box display="flex" gap={1} mr={1}>
                                        {notFoundComponent}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    );
                }}
                renderResultStats={(stats) => {
                    return showResultStats &&
                        (headerFirstLevel || headerSecondLevel) ? (
                        <Grid container alignItems="center">
                            <Grid
                                container
                                sx={{
                                    mb: { xs: 1, sm: 0 },
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                }}
                            >
                                {headerFirstLevel && (
                                    <Grid item xs={12}>
                                        {headerFirstLevel}
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                >
                                    {highlightLegend}
                                    {!hideReport && (
                                        <IconButton
                                            onClick={copyURLToClipboard}
                                        >
                                            <LinkIcon
                                                color="secondary"
                                                fontSize="medium"
                                                sx={{ rotate: '135deg' }}
                                            />
                                        </IconButton>
                                    )}
                                    {!hideReport && (
                                        <IconButton
                                            onClick={() =>
                                                setErrorData({
                                                    source: 'user-report',
                                                    type: 'results-list',
                                                })
                                            }
                                        >
                                            <ReportGmailerrorred color="secondary" />
                                        </IconButton>
                                    )}
                                    {!hideFilter && isMobile && (
                                        <IconButton
                                            onClick={onFilterClick}
                                            sx={{
                                                backgroundColor: colors.hover,
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <GridFilterListIcon color="secondary" />
                                            {hasSelectedFilters && (
                                                <span
                                                    style={{
                                                        content: '""',
                                                        position: 'absolute',
                                                        top: '25%',
                                                        right: '20%',
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor:
                                                            colors.tertiary, // Customize the point color
                                                    }}
                                                ></span>
                                            )}
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>

                            {resultStatsMessage ? (
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    flexWrap="nowrap"
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection="row"
                                        alignItems={'flex-end'}
                                        justifyContent={'flex-start'}
                                    >
                                        <Box textAlign="start">
                                            <ResultStats
                                                message={resultStatsMessage}
                                            />
                                        </Box>
                                    </Box>
                                    {headerSecondLevel && (
                                        <Grid item>{headerSecondLevel}</Grid>
                                    )}
                                </Grid>
                            ) : (
                                headerSecondLevel
                            )}
                        </Grid>
                    ) : (
                        showResultStats &&
                            !headerFirstLevel &&
                            !headerSecondLevel && (
                                <Grid
                                    container
                                    sx={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={
                                            !hideFilter &&
                                            isMobile &&
                                            highlightLegend
                                                ? 12
                                                : 6
                                        }
                                    >
                                        <ResultStats
                                            message={resultStatsMessage}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={
                                            !hideFilter &&
                                            isMobile &&
                                            highlightLegend
                                                ? 12
                                                : 6
                                        }
                                        item
                                        display="flex"
                                        alignItems="center"
                                        flexDirection="row"
                                        justifyContent={
                                            !hideFilter &&
                                            isMobile &&
                                            highlightLegend
                                                ? 'space-between'
                                                : 'flex-end'
                                        }
                                    >
                                        {highlightLegend}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            {!hideReport && (
                                                <IconButton
                                                    onClick={copyURLToClipboard}
                                                >
                                                    <LinkIcon
                                                        color="secondary"
                                                        fontSize="medium"
                                                        sx={{
                                                            rotate: '135deg',
                                                        }}
                                                    />
                                                </IconButton>
                                            )}

                                            {!hideReport && (
                                                <IconButton
                                                    onClick={() => {
                                                        // otherwise it shows up behind the full screen
                                                        // and cannot be immediately seen until the full screen
                                                        // is closed
                                                        fullScreenHandle?.exit();
                                                        setErrorData({
                                                            source: 'user-report',
                                                            type: 'results-list',
                                                        });
                                                    }}
                                                >
                                                    <ReportGmailerrorred color="secondary" />
                                                </IconButton>
                                            )}

                                            {!hideFilter && isMobile && (
                                                <IconButton
                                                    onClick={onFilterClick}
                                                    sx={{
                                                        backgroundColor:
                                                            colors.hover,
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    <GridFilterListIcon color="secondary" />
                                                    {hasSelectedFilters && (
                                                        <span
                                                            style={{
                                                                content: '""',
                                                                position:
                                                                    'absolute',
                                                                top: '25%',
                                                                right: '20%',
                                                                width: '8px',
                                                                height: '8px',
                                                                borderRadius:
                                                                    '50%',
                                                                backgroundColor:
                                                                    colors.tertiary, // Customize the point color
                                                            }}
                                                        ></span>
                                                    )}
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                    );
                }}
                renderPagination={renderPagination}
            />
            <MySnackbar
                anchorOrigin={{
                    vertical: isMobile ? 'bottom' : 'bottom',
                    horizontal: isMobile ? 'center' : 'left',
                }}
                message={showSnackbar.message}
                open={showSnackbar.show}
                severity={showSnackbar.severity}
                onClose={() =>
                    setShowSnackbar({ ...showSnackbar, show: false })
                }
                autoHideDuration={3000}
            />
        </>
    );
};

export default ResultItemList;
