import { Box, styled } from '@mui/material';

export const ResultItemCommentary = styled(Box)(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
}));

export const ResultItemCommentaryRow = styled(Box)(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    borderBottom: '1px rgb(232, 232, 232) solid',
}));
